import React from "react";

import Helmet from "react-helmet";
import styled from "styled-components";

import TopNavigation from "../components/Layout/Navigation";
import NoticeComponent from "../components/Layout/Notice";
import SocialMediaComponent from "../components/SocialMedia";
import Sponsors from "../components/Layout/Sponsors";
import { FixedWidthContainer } from "../components/Layout/ContentContainers";
import Footer from "../components/Layout/Footer";
import { RADIO_PLAYER_HEIGHT } from "../components/Radio/RadioPlayer";
import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

const PAGE_TITLE = "404 - Page Not Found";

class NotFoundPage extends React.Component {
  render() {
    return (
      <PageContainer>
        <Helmet title={PAGE_TITLE}>
          <meta property="og:title" content={PAGE_TITLE} />
        </Helmet>
        <TopNavigation />

        <NoticeComponent />

        <PreContent>
          <Sponsors tier={1} />
          <SocialMediaComponent />
        </PreContent>

        <MainContentContainer>
          <FixedWidthContainer>
            <PageTitle>{PAGE_TITLE}</PageTitle>
            <p>Sorry, nothin&lsquo; here!</p>
          </FixedWidthContainer>
        </MainContentContainer>

        <Sponsors tier={2} />
        <Sponsors tier={3} />

        <Footer />
      </PageContainer>
    );
  }
}

const PageContainer = styled.div`
  margin-top: 0;
  margin-bottom: ${RADIO_PLAYER_HEIGHT + 24}px;

  ${StyledComponentsBreakpoints.Phone`
    margin-top: 88px;
  `};
`;
const PageTitle = styled.h1`
  margin-bottom: 1rem;
`;

const MainContentContainer = styled.main`
  width: 100%;
  margin: 0 auto 48px;
  padding-top: 0;
  z-index: 1;
`;

const PreContent = styled.div`
  padding-top: 12px;
`;

export default NotFoundPage;
